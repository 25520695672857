import React from 'react'
import Link from 'gatsby-link';
import { css } from '@emotion/react'
import { container, fullContainer } from '../../styles/grid'
import { colors } from '../../styles/colors'
import { breakpoints } from '../../styles/breakpoints'

// Images.
import rfd_logo_svg from '../../static/images/rfd-logo.svg'
import heart_white_svg from '../../static/images/heart-white.svg'

let styles;

const RfDHeader = () => (
  <div css={styles.grid}>
    <div css={styles.inner}>
      <div css={styles.title}><Link to="/">React<span> </span>Drupal</Link></div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon fill="white" points="0,100 100,0 100,100"/>
      </svg>
    </div>
  </div>
);

styles = {
  grid: css`
    ${container(12, false)};
  `,
  inner: css`
    ${fullContainer};
    align-items: center;
    background-color: ${colors.blueBunting};
    color: ${colors.white};
    font-size: 1.4em;
    padding-top: 1em;
    text-align: center;

    ${breakpoints.charlieAndUp} {
      text-align: left;
    }

    a,
    a:hover {
      color: ${colors.white};
      text-decoration: none;
    }

    // See https://css-tricks.com/creating-non-rectangular-headers
    svg {
      grid-column: full;
      bottom: 0;
      width: 100%;
      height: 40px;
    }
  `,
  title: css`
    background: no-repeat url(${rfd_logo_svg}) top center ${colors.blueBunting};    
    background-size: 1.6em;
    font-size: 3.2rem;
    margin-top: 0.5em;
    padding: 1.8em 0 0 0;

    ${breakpoints.charlieAndUp} {
      background-position: center left;
      margin-top: 0;
      padding: 0.5em 2em;
    }

    span {
      background: no-repeat url(${heart_white_svg}) center center;
      background-size: 1em;
      color: ${colors.lavenderMagenta};
      display: block;
      padding: 0.5em 0.5em 0.5em 0.5em;
      margin: 0 0.5em;
      text-indent: -999em;
  
      ${breakpoints.charlieAndUp} {
        display: inline;
      }
     }
  `
};

export default RfDHeader
