import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { colors, shadeColor } from '../../styles/colors';
import { sansFamily} from "../../styles/typography";

let styles

// Goal
// <li><a href=""><span>COURSE</span> Link title text goes here</a></li>

const LinkItem = props => (
  <a href={props.uri}>
    {props.tag !== 'none' && <Tag type={props.tag}>{props.tag}</Tag>}
    {props.title}
  </a>
)

LinkItem.defaultProps = {
  tag: 'none',
}

LinkItem.propTypes = {
  tag: PropTypes.oneOf(['none', 'course', 'video', 'podcast', 'code', 'free']),
  title: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
}

const Tag = styled('span')`
  border-left: 8px solid #000;
  color: ${colors.white};
  display: inline-block;
  font-family: ${sansFamily};
  font-size: 0.8em;
  font-weight: bold;
  margin-right: 6px;
  padding: 2px 6px;
  ${props => styles[props.type]};
`

styles = {
  code: `
    background: ${colors.dustyGray};
    border-color: ${shadeColor(colors.dustyGray, -50)};
    :before {
      content: "<";
    }
    :after {
      content: "/>";
    }
  `,
  course: `
    background: ${colors.blueMalibu};
    border-color: ${shadeColor(colors.blueMalibu, -50)};
  `,
  video: `
    background: ${colors.lavenderMagenta};
    border-color: ${shadeColor(colors.lavenderMagenta, -50)};
  `,
  podcast: `
    background: ${colors.blueRoyal};
    border-color: ${shadeColor(colors.blueRoyal, -50)};
  `,
  free: `
    background: ${colors.dmeGreen};
    border-color: ${shadeColor(colors.dmeGreen, -50)};
  `,
}

export default LinkItem
